import React from "react";
import { Input } from "@vippsno/ds-form";
import { CalendarIcon } from "@vippsno/ds-icon";

type InputProps = React.ComponentProps<typeof Input>;
export type Props = Omit<
  InputProps,
  "onChange" | "type" | "append" | "prepend"
>;

export const DatePickerInput = React.forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => {
    const { disabled, error, description, label, ...restProps } = props;

    return (
      <Input
        append={<CalendarIcon size="medium" />}
        autoComplete="off"
        description={description}
        disabled={disabled}
        error={error}
        label={label}
        ref={ref}
        {...restProps}
      />
    );
  },
);

DatePickerInput.displayName = "DatePickerInput";
