import React from "react";
import { Button } from "@vippsno/ds-button";
import { WrappingGeneralDiv, WrappingTxt } from "./styles";
import { Dialog } from "@vippsno/ds-modal";

interface ConfirmWrappingProps {
  isOpen: boolean;
  isActiveWrapping: boolean;
  scheduledStartDate: Date;
  title: string;
  changeTxts: Map<string, string>;
  confirmButtonTxt: string;
  confirmAction: () => Promise<void>;
  onClose: (arg: boolean) => void;
}

function ConfirmWrapping(props: ConfirmWrappingProps) {
  const {
    isOpen,
    isActiveWrapping,
    scheduledStartDate,
    title,
    confirmButtonTxt,
    changeTxts,
    confirmAction,
    onClose,
  } = props;

  const notActive = !isActiveWrapping;
  const outsideScheduledDates =
    isActiveWrapping && scheduledStartDate && scheduledStartDate > new Date();
  const activeNow =
    (isActiveWrapping && !scheduledStartDate) ||
    (isActiveWrapping &&
      scheduledStartDate &&
      scheduledStartDate <= new Date());

  const handleCancelModal = async (
    event: React.MouseEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    onClose(false);
  };

  const handleEscModal = async (
    event: React.SyntheticEvent<HTMLDialogElement>,
  ) => {
    event.preventDefault();
    onClose(false);
  };

  const handleCloseModal = async (
    event:
      | React.SyntheticEvent<HTMLDialogElement, Event>
      | React.SyntheticEvent<HTMLButtonElement, Event>,
  ) => {
    event.preventDefault();
    onClose(false);
  };

  return (
    <Dialog
      header={title}
      open={isOpen}
      closeLabel="No, cancel"
      stickyActions={true}
      onClose={handleEscModal}
      onCancel={handleCloseModal}
      actions={{
        primary: (
          <Button
            key="save"
            variant="primary"
            onClick={confirmAction}
            loading={false}
            disabled={false}
          >
            {confirmButtonTxt}
          </Button>
        ),
        secondary: (
          <Button key="cancel" variant="secondary" onClick={handleCancelModal}>
            No, cancel
          </Button>
        ),
      }}
    >
      <WrappingGeneralDiv css={{ display: "block" }}>
        <WrappingGeneralDiv>
          {changeTxts.size > 0 && (
            <WrappingGeneralDiv
              css={{ "margin-bottom": "20px", display: "block" }}
            >
              {Array.from(changeTxts.entries()).map((entry) => {
                const [key, value] = entry;
                return (
                  <WrappingTxt key={key} css={{ display: "block" }}>
                    <b>{key}</b>
                    {value}
                    <br />
                  </WrappingTxt>
                );
              })}
            </WrappingGeneralDiv>
          )}
        </WrappingGeneralDiv>
        <WrappingGeneralDiv>
          {activeNow && (
            <WrappingTxt>
              The changes will be instantly visible in the app.
            </WrappingTxt>
          )}
          {notActive && (
            <WrappingTxt>
              The changes will be saved, but the wrapping paper won`t be visible
              in the app until it`s activated.
            </WrappingTxt>
          )}
          {outsideScheduledDates && (
            <WrappingTxt>
              The changes will be saved and visible in the app from the
              scheduled start date.
            </WrappingTxt>
          )}
        </WrappingGeneralDiv>
      </WrappingGeneralDiv>
    </Dialog>
  );
}

export default ConfirmWrapping;
