import { styled } from "@stitches/react";
import { Button } from "@vippsno/ds-button";

export const StyledEmoji = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #938fa8",
  borderRadius: "10px",
  fontSize: "xx-large",
  width: "50px",
  height: "50px",
  padding: "10px",
  margin: "0 10px 10px 0",
  position: "relative",
});

export const StyledCloseButton = styled("button", {
  borderRadius: "12px",
  width: "20px",
  height: "20px",
  textAlign: "center",
  padding: "0",
  border: "none",
  fontSize: "medium",
  position: "absolute",
  backgroundColor: "#fff",
  color: "#722ac9",
  top: "0",
  right: "0",
});

export const StyledEmojiContainer = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
});

export const StyledEmojiDiv = styled("div", {
  display: "flex",
  flexDirection: "row",
});

export const StyledEmojiInput = styled("input", {
  width: "50px",
  fontSize: "xx-large",
  border: "1px solid #938fa8",
  borderRadius: "10px",
  height: "50px",
  padding: "10px",
  margin: "0 10px 10px 0",
  textAlign: "center",
});

export const StyledEmojiButton = styled(Button, {
  margin: "0 10px 10px 0 !important",
  borderRadius: "10px !important",
});
