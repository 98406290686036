import {
  CategoryWithAssets,
  GiftAsset,
  GiftAssetRead,
  GiftCategory,
  GiftCategoryRead,
} from "../generated/models";
import { MoneyGiftsAdminConfig } from "../config/money-gifts-admin-config";
import axios, { AxiosError, AxiosResponse } from "axios";
import { IPublicClientApplication } from "@azure/msal-browser";
import { acquireToken } from "../auth/auth";
import { DefaultApi } from "../generated/apis/default-api";

class MoneyGiftsAdminApi {
  api: DefaultApi;
  msalInstance: IPublicClientApplication;

  constructor(msalInstance: IPublicClientApplication) {
    this.api = new DefaultApi(MoneyGiftsAdminConfig);
    this.msalInstance = msalInstance;
  }

  getAllCategories = async (): Promise<Array<CategoryWithAssets>> => {
    const response = await this.callApi((bearerToken) =>
      this.api.getAllCategoriesV1(bearerToken),
    );

    return response?.categories;
  };

  createCategory = async (
    category: GiftCategory,
  ): Promise<GiftCategoryRead> => {
    const response = await this.callApi((bearerToken) =>
      this.api.createCategoryV1(
        {
          category: category,
        },
        bearerToken,
      ),
    );
    return response?.category;
  };

  editCategory = async (
    category: GiftCategory,
    categoryId: number,
  ): Promise<GiftCategoryRead> => {
    const response = await this.callApi((bearerToken) =>
      this.api.updateGiftCategoryV1(
        {
          category: category,
        },
        bearerToken,
        categoryId,
      ),
    );
    return response?.category;
  };

  createAsset = async (asset: GiftAsset): Promise<GiftAssetRead> => {
    const response = await this.callApi((bearerToken) =>
      this.api.createAssetV1({ asset: asset }, bearerToken),
    );

    return response?.asset;
  };

  updateAsset = async (
    asset: GiftAsset,
    assetId: number,
  ): Promise<GiftAssetRead> => {
    const response = await this.callApi((bearerToken) =>
      this.api.updateAssetV1({ asset: asset }, bearerToken, assetId),
    );

    return response?.asset;
  };

  uploadImage = async (file: Blob): Promise<string> => {
    const response = await this.callApi((bearerToken) =>
      this.api.uploadAssetVariantImageV1Form(file, bearerToken),
    );

    return response?.url;
  };

  callApi = async <T>(
    fn: (bearerToken: string) => Promise<AxiosResponse<T>>,
  ): Promise<T> => {
    const bearerToken = await this.getAuthBearerToken();
    try {
      const response = await fn(bearerToken);
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        if (axiosError.response) {
          throw new Error(
            `Http status ${err.response.status}: ${err.response.data.message}`,
          );
        } else if (axiosError.request) {
          throw new Error("No response received for request, try again");
        }
      }
      throw new Error("Request failed, unknown error");
    }
  };

  getAuthBearerToken = async (): Promise<string> => {
    const authResult = await acquireToken(this.msalInstance);
    return `Bearer ${authResult.accessToken}`;
  };
}

export default MoneyGiftsAdminApi;
