import React from "react";
import { Heading4 } from "@vippsno/ds-typography";
import { DragIndicatorIcon } from "@vippsno/ds-icon";
import { InlineColumnHeader, StyledTable } from "@vippsno/ds-table";
import { Button } from "@vippsno/ds-button";
import { Badge } from "@vippsno/ds-badge";
import {
  CategoryWithAssets,
  GiftCategoryRead,
} from "../../../generated/models";
import {
  formatDateRange,
  handleResizeAndOrientationChange,
} from "../../../common/common";
import { GiftAssetRead } from "../../../generated/models/gift-asset-read";
import {
  CenteredDivContent,
  LargeScreenImgStyles,
  SmallScreenImgStyles,
  StyledCategory,
  StyledDiv,
  StyledIcon,
  StyledTh,
} from "./styles";

interface CategoryProps {
  categoryWithAssets: CategoryWithAssets;
  onEditWrappingOpen: (wrapping: GiftAssetRead) => void;
  onEditCategoryOpen: (category: GiftCategoryRead) => void;
}

function Category(props: CategoryProps) {
  const { onEditWrappingOpen, onEditCategoryOpen } = props;
  const { categoryWithAssets } = props;

  const [isSmallScreen, setIsSmallScreen] = React.useState(false);
  React.useEffect(() => {
    handleResizeAndOrientationChange(setIsSmallScreen, window);
  }, []);

  const { category, assets } = categoryWithAssets;
  const giftAssets = assets || [];
  const { title } = category || {};

  const handleEditWrappingClick = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    giftAsset: GiftAssetRead,
  ) => {
    event.stopPropagation();
    onEditWrappingOpen(giftAsset);
  };

  return (
    category && (
      <StyledCategory>
        <StyledDiv>
          <Heading4>{title}</Heading4>
          <StyledIcon onClick={() => onEditCategoryOpen(category)} />
        </StyledDiv>
        <StyledTable stacked={isSmallScreen}>
          <thead>
            <tr>
              <StyledTh />
              <StyledTh>Status</StyledTh>
              <StyledTh>Scheduled</StyledTh>
              <StyledTh>Visuals</StyledTh>
              <StyledTh>Name</StyledTh>
              <StyledTh>Accessibility description</StyledTh>
              <StyledTh />
            </tr>
          </thead>
          <tbody>
            {giftAssets.map((giftAsset) => (
              <tr key={giftAsset.id}>
                <td>
                  <DragIndicatorIcon />
                </td>
                <td>
                  <Badge variant={giftAsset.active ? "success" : "error"}>
                    {giftAsset.active ? "Active" : "Inactive"}
                  </Badge>
                </td>
                <td>
                  {isSmallScreen ? (
                    <InlineColumnHeader>Scheduled</InlineColumnHeader>
                  ) : null}
                  {formatDateRange(
                    giftAsset.activeFromTs,
                    giftAsset.activeToTs,
                  )}
                </td>
                <td>
                  <CenteredDivContent>
                    {giftAsset.variants && giftAsset.variants.length > 0 ? (
                      <img
                        src={giftAsset.variants[0].assetURL}
                        style={
                          isSmallScreen
                            ? SmallScreenImgStyles
                            : LargeScreenImgStyles
                        }
                        alt="Visuals"
                      />
                    ) : null}
                  </CenteredDivContent>
                </td>
                <td>{giftAsset.name}</td>
                <td>
                  {isSmallScreen ? (
                    <InlineColumnHeader>
                      Accessibility description
                    </InlineColumnHeader>
                  ) : null}
                  {giftAsset.accessibilityText}
                </td>
                <td>
                  <Button
                    onClick={(evt) => handleEditWrappingClick(evt, giftAsset)}
                    variant="primary"
                    stretched={isSmallScreen}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </StyledCategory>
    )
  );
}

export default Category;
