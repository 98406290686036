import React from "react";
import { Button } from "@vippsno/ds-button";

function EnvBadge() {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const envProd = "PROD";
  const envUat = "UAT";
  const envDev = "DEV";

  return (
    <div style={{ paddingLeft: "5px" }}>
      {env == envProd && <Button variant="destructive">PROD</Button>}
      {env == envUat && <Button variant="orange">UAT</Button>}
      {env == envDev && <Button variant="orange">DEV</Button>}
    </div>
  );
}

export default EnvBadge;
