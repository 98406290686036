import React from "react";
import { GiftCategory, GiftCategoryRead } from "../../generated/models";
import { useEditCategory } from "../../api/money-gifts-api-hooks";
import CategoryEditor from "./common/CategoryEditor";

interface EditCategoryProps {
  isOpen: boolean;
  onClose: () => void;
  onBannerOpen: (type: string, txt: string) => void;
  category: GiftCategoryRead | null;
}

function EditCategory(props: EditCategoryProps) {
  const { onClose, isOpen, category, onBannerOpen } = props;
  const categoryId = category.id;
  const [failure, setFailure] = React.useState<string>("");

  const onSuccess = () => {
    onBannerOpen("success", "Category was successfully updated");
    resetState();
    onClose();
  };

  const onError = (error: Error) => {
    if (error instanceof Error) {
      setFailure(`Failed to update category: ${error.message}}`);
    } else {
      setFailure("Failed to update category: Unknown error");
    }
  };

  const editCategory = useEditCategory(onSuccess, onError);

  const handleSubmit = async (giftCategory: GiftCategory) => {
    setFailure("");
    if (!categoryId) {
      setFailure("Id missing from existing category, can't edit it");
      return;
    }
    if (giftCategory.title.length == 0) {
      setFailure("Category name is mandatory");
      return;
    }
    if (giftCategory.title == category.title) {
      setFailure("Category name isn't changed");
      return;
    }

    editCategory.mutate({ category: giftCategory, categoryId: categoryId });
  };

  const resetState = () => {
    setFailure("");
  };

  const handleCancel = async () => {
    resetState();
    onClose();
  };

  return (
    <CategoryEditor
      category={category}
      heading={`Edit category "${category.title}"`}
      isOpen={isOpen}
      isLoading={editCategory.isLoading}
      inputValidationFailure={failure}
      onClose={handleCancel}
      onSubmit={handleSubmit}
    />
  );
}

export default EditCategory;
