import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from "@azure/msal-browser"; // add fallback for local development

// add fallback for local development
const apiId = process.env.REACT_APP_API_ID || "";
const apiUrl = process.env.REACT_APP_API_URL || "";

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ["user.read"],
  extraScopesToConsent: ["api://" + apiId + "/Default"],
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
  moneyGiftsApi: {
    base: apiUrl,
    scopes: ["api://" + apiId + "/Default"], // e.g. api://xxxxxx/access_as_user
  },
};

export async function acquireToken(
  msalInstance: IPublicClientApplication,
): Promise<AuthenticationResult> {
  const accounts = msalInstance.getAllAccounts();

  if (!accounts) {
    throw new Error("No accounts detected");
  }

  const account = accounts[0];

  const acquireTokenRequest = {
    ...protectedResources.moneyGiftsApi,
    account,
  };

  try {
    return await msalInstance.acquireTokenSilent(acquireTokenRequest);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return await msalInstance.acquireTokenPopup(acquireTokenRequest);
    } else {
      throw error;
    }
  }
}
