import React from "react";
import ReactDatePicker, { getDefaultLocale } from "react-datepicker"; // needed for esbuild
import { ArrowIcon } from "@vippsno/ds-icon";
import nb from "date-fns/locale/nb";

import {
  DatePickerInput,
  Props as DatePickerInputProps,
} from "./CustomDatePickerInput";

import { StyledDatePicker } from "./styles";

export interface Props extends Omit<DatePickerInputProps, "onChange" | "type"> {
  dateFormat?: string | string[];

  /**
   * Set the locale to use. The default locale is set to `nb` (Norwegian - Bokmål) from `date-fns`, unless the global
   * datepicker default has been overridden.
   *
   * To override the global default, import and use `registerLocale` and `setDefaultLocale` from `@vippsno/ds-datepicker`.
   * Read more about localization in the [react-datepicker documentation](https://www.npmjs.com/package/react-datepicker#localization).
   *
   * @default nb
   */
  locale?: string | Locale;

  maxDate?: Date | null;

  minDate?: Date | null;

  onChange: (
    date: Date | null,
    event: React.SyntheticEvent<HTMLInputElement> | undefined,
  ) => void;

  selected?: Date | null;
}

export const DatePicker = (props: Props) => {
  const {
    className,
    css,
    dateFormat = "dd.MM.yyyy",
    disabled,
    id,
    locale,
    maxDate,
    minDate,
    name,
    onBlur,
    onChange,
    placeholder,
    selected,
    style,
    ...inputProps
  } = props;

  return (
    <StyledDatePicker className={className} css={css} style={style}>
      <ReactDatePicker
        customInput={<DatePickerInput {...inputProps} />}
        dateFormat={dateFormat}
        disabled={disabled}
        id={id}
        locale={locale || getDefaultLocale() || nb}
        maxDate={maxDate}
        minDate={minDate}
        name={name}
        nextMonthButtonLabel={<ArrowIcon direction="right" size="small" />}
        onBlur={onBlur}
        onChange={onChange}
        placeholderText={placeholder}
        previousMonthButtonLabel={<ArrowIcon direction="left" size="small" />}
        selected={selected}
        showDisabledMonthNavigation
        useWeekdaysShort
      />
    </StyledDatePicker>
  );
};
