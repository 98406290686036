import React from "react";
import { Sidesheet } from "@vippsno/ds-modal";
import { Input, InputError, Textarea, Toggle } from "@vippsno/ds-form";
import { Button } from "@vippsno/ds-button";
import {
  GiftAsset,
  GiftAssetEffectTypeEnum,
  GiftAssetVariant,
  GiftAssetVariantTypeEnum,
} from "../../../generated/models";
import { accessibilityDescriptionPlaceholder } from "../../../common/common";
import {
  GeneralForm,
  GeneralSelect,
  StyledDatepicker,
  StyledDatepickers,
  WrappingAddButton,
  WrappingGeneralDiv,
  WrappingHeading4,
  WrappingToggleLabel,
} from "../styles";
import { useCategories } from "../../../api/money-gifts-api-hooks";
import { DatePicker } from "../../common/DatePicker/CustomDatePicker";
import nb from "date-fns/locale/nb";
import { AddIcon } from "@vippsno/ds-icon";
import WrappingVariantForm from "./WrappingVariantForm";
import {
  diffFields,
  isScheduledAsset,
  validatFieldsOnCreate,
} from "../validations";
import ConfirmWrapping from "../ConfirmWrapping";

interface WrappingEditorProps {
  giftAsset: GiftAsset;
  giftAssetId: number;
  heading: string;
  confirmTxt: string;
  confirmButtonTxt: string;
  isNewAsset: boolean;
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (giftAsset: GiftAsset) => Promise<void>;
  onClose: () => void;
}

function WrappingEditor(props: WrappingEditorProps) {
  const {
    giftAsset,
    giftAssetId,
    heading,
    confirmTxt,
    confirmButtonTxt,
    isNewAsset,
    isOpen,
    isLoading,
    onSubmit,
    onClose,
  } = props;

  const categories = useCategories();

  const initialState = { ...giftAsset };
  const [giftWrappingAsset, setGiftWrappingAsset] = React.useState<GiftAsset>({
    ...giftAsset,
  });
  const [isScheduled, setIsScheduled] = React.useState<boolean>(
    isScheduledAsset(giftAsset),
  );
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [inputValidationFailures, setInputValidationFailures] = React.useState<
    string[]
  >([]);

  const handleSaveForm = async (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();

    setInputValidationFailures([]);
    const newFailures: string[] = validatFieldsOnCreate(giftWrappingAsset);
    if (newFailures.length > 0) {
      setInputValidationFailures(newFailures);
      return;
    }
    if (!isNewAsset) {
      const diffs = diffFields(
        giftWrappingAsset,
        initialState,
        categories.data,
      );
      if (diffs.size === 0) {
        setInputValidationFailures(["No changes made, wont update"]);
        return;
      }
    }

    setIsModalOpen(true);
  };

  const handleSubmit = async () => {
    setIsModalOpen(false);
    await onSubmit(giftWrappingAsset);
  };

  const handleVariantChange = (index: number, variant: GiftAssetVariant) => {
    const updatedVariants = [...giftWrappingAsset.variants];
    updatedVariants[index] = variant;

    setGiftWrappingAsset({ ...giftWrappingAsset, variants: updatedVariants });
  };

  const handleAddVariant = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault();

    setGiftWrappingAsset({
      ...giftWrappingAsset,
      variants: [
        ...giftWrappingAsset.variants,
        {
          type: GiftAssetVariantTypeEnum.Light,
          baseColor: "",
          tintColor: "",
          assetURL: "",
          effect: {
            type: GiftAssetEffectTypeEnum.EmojiConfetti,
            emojis: [],
          },
        },
      ],
    });
  };

  const handleIsScheduledChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsScheduled(event.target.checked);
    if (!event.target.checked) {
      setGiftWrappingAsset({
        ...giftWrappingAsset,
        activeFromTs: undefined,
        activeToTs: undefined,
      });
    }
  };

  const diffTxts = () => {
    if (isNewAsset) {
      return new Map<string, string>();
    }
    return diffFields(giftWrappingAsset, initialState, categories.data);
  };

  return (
    <WrappingGeneralDiv>
      <Sidesheet
        open={isOpen}
        onClose={onClose}
        onCancel={onClose}
        header={heading}
        closeLabel="Close"
        actions={{
          primary: (
            <Button
              key="save"
              variant="primary"
              onClick={handleSaveForm}
              loading={isLoading}
              disabled={isLoading}
              loading-label={"Loading"}
            >
              Save changes
            </Button>
          ),
          secondary: (
            <Button
              key="cancel"
              variant="secondary"
              onClick={onClose}
              loading-label={"Loading"}
            >
              Cancel
            </Button>
          ),
        }}
      >
        <GeneralForm>
          <WrappingHeading4>General</WrappingHeading4>
          {isNewAsset && (
            <WrappingGeneralDiv style={{ marginBottom: "16px" }}>
              <Input
                name="name"
                label="Name"
                style={{ marginBottom: "16px" }}
                value={giftWrappingAsset.name}
                onChange={(e) =>
                  setGiftWrappingAsset({
                    ...giftWrappingAsset,
                    name: e.target.value,
                  })
                }
              />
            </WrappingGeneralDiv>
          )}
          {!isNewAsset && (
            <WrappingGeneralDiv style={{ marginBottom: "16px" }}>
              <Input
                name="shortName"
                label="Short name"
                style={{ marginRight: "10px" }}
                disabled
                value={giftWrappingAsset.name}
              />
              <Input
                name="ID"
                label="ID"
                style={{ marginLeft: "10px" }}
                disabled
                value={giftAssetId}
              />
            </WrappingGeneralDiv>
          )}
          <WrappingHeading4>Scheduling</WrappingHeading4>
          <WrappingGeneralDiv
            style={{ alignItems: "center", marginBottom: "16px" }}
          >
            <WrappingToggleLabel>Active</WrappingToggleLabel>
            <Toggle
              name="active"
              checked={giftWrappingAsset.active}
              onChange={(e) =>
                setGiftWrappingAsset({
                  ...giftWrappingAsset,
                  active: e.target.checked,
                })
              }
            />
          </WrappingGeneralDiv>
          <WrappingGeneralDiv
            style={{ alignItems: "center", marginTop: "16px" }}
          >
            <WrappingToggleLabel>Scheduled</WrappingToggleLabel>
            <Toggle
              name="scheduled"
              checked={isScheduled}
              onChange={handleIsScheduledChange}
            />
          </WrappingGeneralDiv>
          {isScheduled && (
            <StyledDatepickers>
              <StyledDatepicker
                label="Start"
                selected={
                  giftWrappingAsset.activeFromTs != undefined
                    ? new Date(giftWrappingAsset.activeFromTs)
                    : undefined
                }
                onChange={(e) =>
                  setGiftWrappingAsset({
                    ...giftWrappingAsset,
                    activeFromTs: e.toISOString(),
                  })
                }
                dateFormat="dd.MM.yyyy"
                locale={nb}
              />
              <DatePicker
                label="Stop"
                selected={
                  giftWrappingAsset.activeToTs != undefined
                    ? new Date(giftWrappingAsset.activeToTs)
                    : undefined
                }
                onChange={(e) =>
                  setGiftWrappingAsset({
                    ...giftWrappingAsset,
                    activeToTs: e.toISOString(),
                  })
                }
                dateFormat="dd.MM.yyyy"
                locale={nb}
              />
            </StyledDatepickers>
          )}
          <WrappingHeading4>Category</WrappingHeading4>
          <GeneralSelect
            name="categories"
            value={giftWrappingAsset.categoryId || 0}
            onChange={(e) =>
              setGiftWrappingAsset({
                ...giftWrappingAsset,
                categoryId: parseInt(e.target.value),
              })
            }
          >
            {categories.data &&
              Array.isArray(categories.data) &&
              categories.data.map((categoryWithAssets) => {
                const { category } = categoryWithAssets;
                if (category) {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  );
                }
              })}
          </GeneralSelect>
          <WrappingHeading4>Accessibility</WrappingHeading4>
          <Textarea
            name="accessibilityDescription"
            label="Accessibility description"
            value={giftWrappingAsset.accessibilityText}
            onChange={(e) =>
              setGiftWrappingAsset({
                ...giftWrappingAsset,
                accessibilityText: e.target.value,
              })
            }
            placeholder={accessibilityDescriptionPlaceholder}
          />
          <WrappingHeading4>Variants</WrappingHeading4>
          {giftWrappingAsset.variants &&
            Array.isArray(giftWrappingAsset.variants) &&
            giftWrappingAsset.variants.map((variant, index) => {
              return (
                <WrappingVariantForm
                  key={index}
                  variant={variant}
                  onVariantChange={(updatedVariant) =>
                    handleVariantChange(index, updatedVariant)
                  }
                />
              );
            })}
          <WrappingAddButton
            variant="secondary"
            icon={<AddIcon />}
            onClick={handleAddVariant}
            disabled={giftWrappingAsset.variants.length > 1}
          >
            Add new variant
          </WrappingAddButton>
          {inputValidationFailures &&
            inputValidationFailures.map((validationFailure, index) => {
              return (
                <WrappingGeneralDiv key={index}>
                  <InputError>{validationFailure}</InputError>
                </WrappingGeneralDiv>
              );
            })}
        </GeneralForm>
      </Sidesheet>
      <ConfirmWrapping
        isOpen={isModalOpen}
        isActiveWrapping={giftWrappingAsset.active}
        scheduledStartDate={
          giftWrappingAsset.activeFromTs != undefined
            ? new Date(giftWrappingAsset.activeFromTs)
            : undefined
        }
        title={confirmTxt}
        confirmButtonTxt={confirmButtonTxt}
        changeTxts={diffTxts()}
        confirmAction={handleSubmit}
        onClose={setIsModalOpen}
      />
    </WrappingGeneralDiv>
  );
}

export default WrappingEditor;
