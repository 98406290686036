import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import msalInstance from "./auth/msal-instance";
import MoneyGiftsAdminApi from "./api/money-gifts-api";
import { MsalProvider } from "@azure/msal-react";
import { MoneyGiftsAdminApiContext } from "./api/money-gifts-api-hooks";

const queryClient: QueryClient = new QueryClient();

const moneyGiftsAdminApi = new MoneyGiftsAdminApi(msalInstance);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <MsalProvider instance={msalInstance}>
          <MoneyGiftsAdminApiContext.Provider value={moneyGiftsAdminApi}>
            <App />
          </MoneyGiftsAdminApiContext.Provider>
        </MsalProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
