import { styled } from "@vippsno/ds-core";
import { Button } from "@vippsno/ds-button";

export const StyledSidebar = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "10px",
});

export const StyledButton = styled(Button, {
  margin: "0 10px 10px 10px",
});

export const SidebarLink = styled("a", {
  textDecoration: "none",
  color: "inherit",
});

export const SidebarDiv = styled("div", {
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
});

export const SidebarLogoDiv = styled("div", {
  width: "50px",
  height: "50px",
  marginRight: "10px",
});

export const SidebarLogo = styled("img", {
  width: "100%",
  height: "auto",
});
