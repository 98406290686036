import React from "react";
import { ColorResult, TwitterPicker } from "react-color";
import {
  GiftAssetVariant,
  GiftAssetVariantTypeEnum,
} from "../../../generated/models";
import WrappingVariantEmojiContainer from "../../content/wrappingVariants/WrappingVariantEmojiContainer";
import { popularColors } from "../../../common/common";
import {
  GeneralSelect,
  PictureReplaceInput,
  WrappingGeneralDiv,
  WrappingVariantLabel,
} from "../styles";
import { useUploadImage } from "../../../api/money-gifts-api-hooks";
import { InputError } from "@vippsno/ds-form";

interface WrappingVariantFormProps {
  variant: GiftAssetVariant;
  onVariantChange: (updatedVariant: GiftAssetVariant) => void;
}

function WrappingVariantForm(props: WrappingVariantFormProps) {
  const { onVariantChange } = props;
  const { variant } = props;

  const [imgUploadFailure, setImgUploadFailure] = React.useState<string>("");
  const [uploadedImgUrl, setUploadedImgUrl] = React.useState<string>(
    variant.assetURL || "",
  );

  const onUploadSuccess = (url: string) => {
    setUploadedImgUrl(url);
    onVariantChange({ ...variant, assetURL: url });
  };
  const onUploadError = (error: Error) => {
    if (error instanceof Error) {
      setImgUploadFailure(`Failed to upload image: ${error.message}`);
    } else {
      setImgUploadFailure("Failed to upload image: Unknown error");
    }
  };

  const uploadImage = useUploadImage(onUploadSuccess, onUploadError);

  const handleAssetURLChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files[0];
    if (file) {
      setUploadedImgUrl("");
      const blob = new Blob([file], { type: "img/png" });
      uploadImage.mutate({ file: blob });
    }
  };
  const handleBaseColorChange = (color: ColorResult) => {
    onVariantChange({ ...variant, baseColor: color.hex });
  };

  const handleTintColorChange = (color: ColorResult) => {
    onVariantChange({ ...variant, tintColor: color.hex });
  };

  const handleEmojisChange = (emojis: string[]) => {
    onVariantChange({
      ...variant,
      effect: { ...variant.effect, emojis: emojis },
    });
  };

  const handleModeChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    onVariantChange({
      ...variant,
      type: evt.target.value as GiftAssetVariantTypeEnum,
    });
  };

  return (
    <>
      <WrappingVariantLabel>Mode</WrappingVariantLabel>
      <GeneralSelect
        name="mode"
        placeholder="Mode"
        value={variant.type}
        onChange={handleModeChange}
      >
        <option value={GiftAssetVariantTypeEnum.Light}>Light</option>
        <option value={GiftAssetVariantTypeEnum.Dark}>Dark</option>
      </GeneralSelect>
      <WrappingVariantLabel>Base color</WrappingVariantLabel>
      <TwitterPicker
        width="100%"
        colors={popularColors}
        triangle="hide"
        onChangeComplete={handleBaseColorChange}
        color={variant.baseColor}
      />
      <WrappingVariantLabel>Tint color</WrappingVariantLabel>
      <TwitterPicker
        width="100%"
        colors={popularColors}
        triangle="hide"
        onChangeComplete={handleTintColorChange}
        color={variant.tintColor}
      />
      <WrappingVariantLabel>Picture</WrappingVariantLabel>
      <WrappingGeneralDiv>
        <PictureReplaceInput type="file" onChange={handleAssetURLChange} />
        {uploadedImgUrl && (
          <img
            src={uploadedImgUrl}
            alt={variant.type + "variant image"}
            style={{
              width: "50px",
              border: "2px solid #C0C0C0",
              padding: "5px",
            }}
          />
        )}
        {imgUploadFailure && (
          <WrappingGeneralDiv>
            <InputError>{imgUploadFailure}</InputError>
          </WrappingGeneralDiv>
        )}
      </WrappingGeneralDiv>
      <WrappingVariantLabel>Emojis</WrappingVariantLabel>
      <WrappingVariantEmojiContainer
        emojis={variant.effect.emojis}
        updateEmojisSet={handleEmojisChange}
      />
    </>
  );
}

export default WrappingVariantForm;
