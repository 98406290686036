import React from "react";
import { GiftCategory } from "../../generated/models";
import { useCreateCategory } from "../../api/money-gifts-api-hooks";
import CategoryEditor from "./common/CategoryEditor";

interface CreateCategoryProps {
  isOpen: boolean;
  onClose: () => void;
  onBannerOpen: (type: string, txt: string) => void;
}

function CreateCategory(props: CreateCategoryProps) {
  const { onClose, onBannerOpen, isOpen } = props;

  const initialEmptyCategory: GiftCategory = {
    title: "",
    active: true,
    sortIndex: 0,
  };
  const [failure, setFailure] = React.useState<string>("");

  const resetState = () => {
    setFailure("");
  };

  const onError = (error: Error) => {
    if (error instanceof Error) {
      setFailure(`Failed to create category: ${error.message}}`);
    } else {
      setFailure("Failed to create category: Unknown error");
    }
  };

  const onSuccess = () => {
    onBannerOpen("success", "Category was successfully created");
    resetState();
    onClose();
  };

  const createCategory = useCreateCategory(onSuccess, onError);

  const handleSubmit = async (giftCategory: GiftCategory) => {
    setFailure("");
    if (giftCategory.title.length == 0) {
      setFailure("Category name is mandatory");
      return;
    }

    const newCategory: GiftCategory = {
      title: giftCategory.title,
      sortIndex: 0,
      active: true,
    };

    createCategory.mutate({ category: newCategory });
  };

  const handleCancel = async () => {
    resetState();
    onClose();
  };

  return (
    <CategoryEditor
      category={initialEmptyCategory}
      heading={"Add new category"}
      isOpen={isOpen}
      isLoading={createCategory.isLoading}
      inputValidationFailure={failure}
      onClose={handleCancel}
      onSubmit={handleSubmit}
    />
  );
}

export default CreateCategory;
