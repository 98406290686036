import React from "react";
import { AddIcon } from "@vippsno/ds-icon";
import {
  BACKSPACE_KEY,
  DELETE_KEY,
  emojiRegex,
  MAX_NUMBER_OF_EMOJIS,
} from "../../../common/common";
import WrappingVariantEmoji from "./WrappingVariantEmoji";
import {
  StyledEmojiButton,
  StyledEmojiContainer,
  StyledEmojiDiv,
  StyledEmojiInput,
} from "./styles";

interface WrappingVariantEmojiContainerProps {
  emojis: string[];
  updateEmojisSet: (emojis: string[]) => void;
}

function WrappingVariantEmojiContainer(
  props: WrappingVariantEmojiContainerProps,
) {
  const { updateEmojisSet } = props;
  const { emojis } = props;

  const [emoji, setEmoji] = React.useState("");
  const [internalEmojis, setInternalEmojis] = React.useState<string[]>(emojis);

  React.useEffect(() => {
    if (emojis) {
      setInternalEmojis(
        emojis.map((emoji) => {
          if (emoji) {
            return emoji;
          }
        }),
      );
    }
  }, [emojis]);

  const handleAddEmoji = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    if (emoji) {
      if (
        internalEmojis.includes(emoji) ||
        internalEmojis.length >= MAX_NUMBER_OF_EMOJIS
      ) {
        setEmoji("");
        return;
      }

      setInternalEmojis([...internalEmojis, emoji]);
      updateEmojisSet([...internalEmojis, emoji]);
      setEmoji("");
    }
  };

  const handleEmojisChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    const emojis = value.match(emojiRegex);

    if (emojis) {
      const lastEmoji = emojis[emojis.length - 1];
      setEmoji(lastEmoji);
    }
  };

  const handleRemoveEmoji = (emoji: string) => {
    const newEmojis = internalEmojis.filter((internalEmoji) => {
      return internalEmoji !== emoji;
    });

    setInternalEmojis(newEmojis);
    updateEmojisSet(newEmojis);
  };

  const handleClearEmojiInput = (evt: React.KeyboardEvent) => {
    const pressedKey = evt.key;

    if (pressedKey === BACKSPACE_KEY || pressedKey === DELETE_KEY) {
      setEmoji("");
    }
  };

  return (
    internalEmojis && (
      <StyledEmojiContainer>
        {internalEmojis.map((emoji) => {
          return (
            <WrappingVariantEmoji
              key={emoji}
              emoji={emoji}
              removeEmoji={handleRemoveEmoji}
            />
          );
        })}
        <StyledEmojiDiv>
          <StyledEmojiButton
            disabled={internalEmojis.length >= MAX_NUMBER_OF_EMOJIS}
            variant="secondary"
            icon={<AddIcon />}
            onClick={handleAddEmoji}
          >
            Add
          </StyledEmojiButton>
          <StyledEmojiInput
            type="text"
            disabled={internalEmojis.length >= MAX_NUMBER_OF_EMOJIS}
            onInput={handleEmojisChange}
            onKeyDown={handleClearEmojiInput}
            value={emoji}
          />
        </StyledEmojiDiv>
      </StyledEmojiContainer>
    )
  );
}

export default WrappingVariantEmojiContainer;
