import React from "react";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../../../auth/auth";
import { StyledButton } from "./styles";

const handleLogin = async (instance: IPublicClientApplication) => {
  try {
    await instance.loginRedirect(loginRequest);
  } catch (error) {
    console.log(error);
  }
};

export function SignInButton() {
  const { instance } = useMsal();

  return (
    <UnauthenticatedTemplate>
      <StyledButton variant="primary" onClick={() => handleLogin(instance)}>
        Sign in
      </StyledButton>
    </UnauthenticatedTemplate>
  );
}
