import React from "react";
import { CloseIcon } from "@vippsno/ds-icon";
import { StyledEmoji, StyledCloseButton } from "./styles";

interface WrappingVariantEmojiProps {
  emoji: string;
  removeEmoji: (emoji: string) => void;
}

function WrappingVariantEmoji(props: WrappingVariantEmojiProps) {
  const { removeEmoji } = props;

  const { emoji } = props;
  return (
    <StyledEmoji>
      {emoji}
      <StyledCloseButton onClick={() => removeEmoji(emoji)}>
        <CloseIcon />
      </StyledCloseButton>
    </StyledEmoji>
  );
}

export default WrappingVariantEmoji;
