import {
  Configuration,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";

// add fallback for local development
const tenantId = process.env.REACT_APP_TENANT_ID || "";
const clientId = process.env.REACT_APP_CLIENT_ID || "";

const msalConfig: Configuration = {
  auth: {
    clientId: clientId, // This is the ONLY mandatory field that you need to supply.
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    // Docs:
    // https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-logging-js
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
        }
      },
    },
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
