import React from "react";
import { GridBlock } from "@vippsno/ds-grid";
import { InteractionStatus } from "@azure/msal-browser";
import {
  useMsal,
  useIsAuthenticated,
  AuthenticatedTemplate,
} from "@azure/msal-react";
import { loginRequest } from "../auth/auth";
import Sidebar from "./sidebar/Sidebar";
import Content from "./content/Content";
import { StyledGrid, StyledGridBlock } from "./styles";

function MainComponent() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  React.useEffect(() => {
    const login = async () => {
      if (inProgress === InteractionStatus.None && !isAuthenticated) {
        try {
          await instance.loginRedirect(loginRequest);
        } catch (error) {
          console.log(error);
        }
      }
    };
    login();
  }, [isAuthenticated, inProgress, instance]);

  return (
    <div className="App">
      <StyledGrid>
        <StyledGridBlock xsm={12} sm={12} md={12} lg={3} xlg={3}>
          <Sidebar />
        </StyledGridBlock>
        <GridBlock xsm={12} sm={12} md={12} lg={9} xlg={9}>
          <AuthenticatedTemplate>
            <Content />
          </AuthenticatedTemplate>
        </GridBlock>
      </StyledGrid>
    </div>
  );
}

export default MainComponent;
