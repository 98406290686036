import React from "react";
import { GiftAsset, GiftAssetRead } from "../../generated/models";
import { WrappingGeneralDiv } from "./styles";
import { useUpdateAsset } from "../../api/money-gifts-api-hooks";
import WrappingEditor from "./common/WrappingEditor";

interface EditWrappingProps {
  giftAsset: GiftAssetRead | null;
  isOpen: boolean;
  onClose: () => void;
  onBannerOpen: (type: string, txt: string) => void;
}

function EditWrapping(props: EditWrappingProps) {
  const { onClose, isOpen, giftAsset, onBannerOpen } = props;

  const giftAssetId = giftAsset.id;
  const giftAssetName = giftAsset.name;

  const onError = (error: Error) => {
    const errorMsg = error instanceof Error ? error.message : "Unknown error";
    onBannerOpen("error", `Failed to update wrapping paper: ${errorMsg}`);
    onClose();
  };

  const onSuccess = () => {
    onBannerOpen("success", "Wrapping paper was successfully updated");
    onClose();
  };

  const updateAsset = useUpdateAsset(onSuccess, onError);

  const handleSubmit = async (giftWrappingAsset: GiftAsset) => {
    const asset: GiftAsset = {
      ...giftWrappingAsset,
      fee: giftWrappingAsset.fee || 0,
    };

    updateAsset.mutate({ asset: asset, assetId: giftAssetId });
  };

  return (
    <WrappingGeneralDiv>
      <WrappingEditor
        giftAsset={giftAsset}
        giftAssetId={giftAssetId}
        heading={`Edit "${giftAssetName}"`}
        confirmTxt={"Do you want to save the changes?"}
        confirmButtonTxt={"Yes, save changes"}
        isNewAsset={false}
        isOpen={isOpen}
        isLoading={updateAsset.isLoading}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </WrappingGeneralDiv>
  );
}

export default EditWrapping;
