import { styled } from "@vippsno/ds-core";

export const StyledContent = styled("div", {
  margin: "10px",
});

export const StyledWrappingsHeading = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
