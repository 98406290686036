import React from "react";
import { handleResizeAndOrientationChange } from "../../../common/common";
import SmallScreenWrappingsHeading from "./SmallScreenWrappingsHeading";
import LargeScreenWrappingsHeading from "./LargeScreenWrappingsHeading";

export interface WrappingsHeadingProps {
  onCreateWrappingOpen: () => void;
  onCreateCategoryOpen: () => void;
}

function WrappingsHeading(props: WrappingsHeadingProps) {
  const [isSmallScreen, setIsSmallScreen] = React.useState(false);

  React.useEffect(() => {
    handleResizeAndOrientationChange(setIsSmallScreen, window);
  }, []);

  const { onCreateCategoryOpen, onCreateWrappingOpen } = props;

  return isSmallScreen ? (
    <SmallScreenWrappingsHeading
      onCreateWrappingOpen={onCreateWrappingOpen}
      onCreateCategoryOpen={onCreateCategoryOpen}
    />
  ) : (
    <LargeScreenWrappingsHeading
      onCreateWrappingOpen={onCreateWrappingOpen}
      onCreateCategoryOpen={onCreateCategoryOpen}
    />
  );
}

export default WrappingsHeading;
