import React from "react";
import Category from "./Category";
import { GiftAssetRead, GiftCategoryRead } from "../../../generated/models";
import { StyledCategories } from "./styles";
import { useCategories } from "../../../api/money-gifts-api-hooks";

interface CategoriesProps {
  onEditWrappingOpen: (wrapping: GiftAssetRead) => void;
  onEditCategoryOpen: (category: GiftCategoryRead) => void;
}

function Categories(props: CategoriesProps) {
  const { onEditWrappingOpen, onEditCategoryOpen } = props;

  const categories = useCategories();

  return (
    <StyledCategories>
      {categories.data &&
        Array.isArray(categories.data) &&
        categories.data.map((categoryWithAssets) => {
          if (categoryWithAssets.category) {
            return (
              <Category
                key={categoryWithAssets.category.id}
                categoryWithAssets={categoryWithAssets}
                onEditWrappingOpen={onEditWrappingOpen}
                onEditCategoryOpen={onEditCategoryOpen}
              />
            );
          }
        })}
    </StyledCategories>
  );
}

export default Categories;
