/* tslint:disable */
/* eslint-disable */
/**
 * Vipps MobilePay Gift Wrappings Admin API
 * API with endpoints for admin users
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GiftAssetEffect } from './gift-asset-effect';
/**
 * 
 * @export
 * @interface GiftAssetVariant
 */
export interface GiftAssetVariant {
    /**
     * 
     * @type {string}
     * @memberof GiftAssetVariant
     */
    type: GiftAssetVariantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GiftAssetVariant
     */
    baseColor: string;
    /**
     * 
     * @type {string}
     * @memberof GiftAssetVariant
     */
    tintColor: string;
    /**
     * 
     * @type {string}
     * @memberof GiftAssetVariant
     */
    assetURL: string;
    /**
     * 
     * @type {GiftAssetEffect}
     * @memberof GiftAssetVariant
     */
    effect: GiftAssetEffect;
}

/**
    * @export
    * @enum {string}
    */
export enum GiftAssetVariantTypeEnum {
    Light = 'light',
    Dark = 'dark'
}

