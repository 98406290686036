import React from "react";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { StyledButton } from "./styles";

const handleLogout = async (instance: IPublicClientApplication) => {
  try {
    const activeAccount = instance.getActiveAccount();
    await instance.logoutRedirect({
      account: activeAccount,
    });
  } catch (error) {
    console.log(error);
  }
};

export function SignOutButton() {
  const { instance } = useMsal();

  return (
    <AuthenticatedTemplate>
      <StyledButton variant="primary" onClick={() => handleLogout(instance)}>
        Sign out
      </StyledButton>
    </AuthenticatedTemplate>
  );
}
