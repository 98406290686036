import { styled } from "@vippsno/ds-core";
import { PencilSquareIcon } from "@vippsno/ds-icon";

export const StyledCategories = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  margin: "1rem",
});

export const StyledCategory = styled("div", {
  margin: "20px 0",
  width: "100%",
});

export const StyledIcon = styled(PencilSquareIcon, {
  marginLeft: "5px",
  marginBottom: "0.5rem",
});

export const StyledDiv = styled("div", {
  display: "flex",
  alignItems: "center",
  width: "100%",
});

export const CenteredDivContent = styled("div", {
  textAlign: "center",
});

export const StyledTh = styled("th", {
  textAlign: "left",
  fontWeight: "bold",
  padding: "0.5rem",
  borderBottom: "1px solid #ccc",
});

export const SmallScreenImgStyles = {
  width: "100%",
};

export const LargeScreenImgStyles = {
  width: "75px",
  height: "75px",
  objectFit: "cover",
};
