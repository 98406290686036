/* tslint:disable */
/* eslint-disable */
/**
 * Vipps MobilePay Gift Wrappings Admin API
 * API with endpoints for admin users
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AllGiftCategoriesResponse } from '../models';
import { AssetBody } from '../models';
import { CategoryBody } from '../models';
import { GiftAssetHistoryResponse } from '../models';
import { GiftAssetResponse } from '../models';
import { GiftCategoryResponse } from '../models';
import { ImageUrlResponse } from '../models';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Gift Asset
         * @param {AssetBody} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssetV1: async (body: AssetBody, authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createAssetV1.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling createAssetV1.');
            }
            const localVarPath = `/v1/gift/asset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Gift Category
         * @param {CategoryBody} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategoryV1: async (body: CategoryBody, authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCategoryV1.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling createCategoryV1.');
            }
            const localVarPath = `/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Gift Categories
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCategoriesV1: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getAllCategoriesV1.');
            }
            const localVarPath = `/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the edit history of a single Gift Asset
         * @param {string} authorization 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetHistoryV1: async (authorization: string, assetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getAssetHistoryV1.');
            }
            // verify required parameter 'assetId' is not null or undefined
            if (assetId === null || assetId === undefined) {
                throw new RequiredError('assetId','Required parameter assetId was null or undefined when calling getAssetHistoryV1.');
            }
            const localVarPath = `/v1/gift/asset/{assetId}/history`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Gift Asset
         * @param {string} authorization 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetV1: async (authorization: string, assetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getAssetV1.');
            }
            // verify required parameter 'assetId' is not null or undefined
            if (assetId === null || assetId === undefined) {
                throw new RequiredError('assetId','Required parameter assetId was null or undefined when calling getAssetV1.');
            }
            const localVarPath = `/v1/gift/asset/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Gift Asset
         * @param {AssetBody} body 
         * @param {string} authorization 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetV1: async (body: AssetBody, authorization: string, assetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateAssetV1.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling updateAssetV1.');
            }
            // verify required parameter 'assetId' is not null or undefined
            if (assetId === null || assetId === undefined) {
                throw new RequiredError('assetId','Required parameter assetId was null or undefined when calling updateAssetV1.');
            }
            const localVarPath = `/v1/gift/asset/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Gift Category
         * @param {CategoryBody} body 
         * @param {string} authorization 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGiftCategoryV1: async (body: CategoryBody, authorization: string, categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateGiftCategoryV1.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling updateGiftCategoryV1.');
            }
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling updateGiftCategoryV1.');
            }
            const localVarPath = `/v1/category/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload an image for an asset variant. 
         * @param {Blob} file 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAssetVariantImageV1Form: async (file: Blob, authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadAssetVariantImageV1Form.');
            }
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling uploadAssetVariantImageV1Form.');
            }
            const localVarPath = `/v1/asset/variant/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new Gift Asset
         * @param {AssetBody} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssetV1(body: AssetBody, authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GiftAssetResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createAssetV1(body, authorization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a new Gift Category
         * @param {CategoryBody} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategoryV1(body: CategoryBody, authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GiftCategoryResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createCategoryV1(body, authorization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get All Gift Categories
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCategoriesV1(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AllGiftCategoriesResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllCategoriesV1(authorization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the edit history of a single Gift Asset
         * @param {string} authorization 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetHistoryV1(authorization: string, assetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GiftAssetHistoryResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAssetHistoryV1(authorization, assetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Single Gift Asset
         * @param {string} authorization 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetV1(authorization: string, assetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GiftAssetResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAssetV1(authorization, assetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a Gift Asset
         * @param {AssetBody} body 
         * @param {string} authorization 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssetV1(body: AssetBody, authorization: string, assetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GiftAssetResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateAssetV1(body, authorization, assetId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a Gift Category
         * @param {CategoryBody} body 
         * @param {string} authorization 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGiftCategoryV1(body: CategoryBody, authorization: string, categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<GiftCategoryResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateGiftCategoryV1(body, authorization, categoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Upload an image for an asset variant. 
         * @param {Blob} file 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAssetVariantImageV1Form(file: Blob, authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ImageUrlResponse>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).uploadAssetVariantImageV1Form(file, authorization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a new Gift Asset
         * @param {AssetBody} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssetV1(body: AssetBody, authorization: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GiftAssetResponse>> {
            return DefaultApiFp(configuration).createAssetV1(body, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Gift Category
         * @param {CategoryBody} body 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategoryV1(body: CategoryBody, authorization: string, options?: AxiosRequestConfig): Promise<AxiosResponse<GiftCategoryResponse>> {
            return DefaultApiFp(configuration).createCategoryV1(body, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Gift Categories
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCategoriesV1(authorization: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AllGiftCategoriesResponse>> {
            return DefaultApiFp(configuration).getAllCategoriesV1(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the edit history of a single Gift Asset
         * @param {string} authorization 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetHistoryV1(authorization: string, assetId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<GiftAssetHistoryResponse>> {
            return DefaultApiFp(configuration).getAssetHistoryV1(authorization, assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Gift Asset
         * @param {string} authorization 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetV1(authorization: string, assetId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<GiftAssetResponse>> {
            return DefaultApiFp(configuration).getAssetV1(authorization, assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Gift Asset
         * @param {AssetBody} body 
         * @param {string} authorization 
         * @param {number} assetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssetV1(body: AssetBody, authorization: string, assetId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<GiftAssetResponse>> {
            return DefaultApiFp(configuration).updateAssetV1(body, authorization, assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Gift Category
         * @param {CategoryBody} body 
         * @param {string} authorization 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGiftCategoryV1(body: CategoryBody, authorization: string, categoryId: number, options?: AxiosRequestConfig): Promise<AxiosResponse<GiftCategoryResponse>> {
            return DefaultApiFp(configuration).updateGiftCategoryV1(body, authorization, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload an image for an asset variant. 
         * @param {Blob} file 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAssetVariantImageV1Form(file: Blob, authorization: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ImageUrlResponse>> {
            return DefaultApiFp(configuration).uploadAssetVariantImageV1Form(file, authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Create a new Gift Asset
     * @param {AssetBody} body 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async createAssetV1(body: AssetBody, authorization: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GiftAssetResponse>> {
        return DefaultApiFp(this.configuration).createAssetV1(body, authorization, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a new Gift Category
     * @param {CategoryBody} body 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async createCategoryV1(body: CategoryBody, authorization: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<GiftCategoryResponse>> {
        return DefaultApiFp(this.configuration).createCategoryV1(body, authorization, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get All Gift Categories
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async getAllCategoriesV1(authorization: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AllGiftCategoriesResponse>> {
        return DefaultApiFp(this.configuration).getAllCategoriesV1(authorization, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the edit history of a single Gift Asset
     * @param {string} authorization 
     * @param {number} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async getAssetHistoryV1(authorization: string, assetId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<GiftAssetHistoryResponse>> {
        return DefaultApiFp(this.configuration).getAssetHistoryV1(authorization, assetId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get Single Gift Asset
     * @param {string} authorization 
     * @param {number} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async getAssetV1(authorization: string, assetId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<GiftAssetResponse>> {
        return DefaultApiFp(this.configuration).getAssetV1(authorization, assetId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update a Gift Asset
     * @param {AssetBody} body 
     * @param {string} authorization 
     * @param {number} assetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async updateAssetV1(body: AssetBody, authorization: string, assetId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<GiftAssetResponse>> {
        return DefaultApiFp(this.configuration).updateAssetV1(body, authorization, assetId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update a Gift Category
     * @param {CategoryBody} body 
     * @param {string} authorization 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async updateGiftCategoryV1(body: CategoryBody, authorization: string, categoryId: number, options?: AxiosRequestConfig) : Promise<AxiosResponse<GiftCategoryResponse>> {
        return DefaultApiFp(this.configuration).updateGiftCategoryV1(body, authorization, categoryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Upload an image for an asset variant. 
     * @param {Blob} file 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public async uploadAssetVariantImageV1Form(file: Blob, authorization: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ImageUrlResponse>> {
        return DefaultApiFp(this.configuration).uploadAssetVariantImageV1Form(file, authorization, options).then((request) => request(this.axios, this.basePath));
    }
}
