import { styled } from "@vippsno/ds-core";
import { Button } from "@vippsno/ds-button";
import { Heading4 } from "@vippsno/ds-typography";
import { Input, Label, Select } from "@vippsno/ds-form";
import { Dialog } from "@vippsno/ds-modal";
import { DatePicker } from "../common/DatePicker/CustomDatePicker";

export const StyledDatepickers = styled("div", {
  fontFamily: "Vipps, Arial, sans-serif;",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: "16px",
  fontWeight: "$regular",
});

export const StyledDatepicker = styled(DatePicker, {
  marginRight: "16px",
});

export const StyledDialogModal = styled(Dialog, {
  backgroundColor: "rgba(128, 128, 128, 0.5)",
});

export const StyledDialogModalContent = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  boxSizing: "border-box",
});

export const WrappingVariantLabel = styled(Label, {
  margin: "20px 0px",
  width: "auto",
});

export const GeneralForm = styled("form", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

export const PictureReplaceInput = styled(Input, {
  marginRight: "8px",
});

export const WrappingHeading4 = styled(Heading4, {
  marginBottom: "20px",
  marginTop: "20px",
});

export const WrappingToggleLabel = styled(Label, {
  marginRight: "8px",
  width: "auto",
});

export const GeneralSelect = styled(Select, {
  marginBottom: "16px",
  marginRight: "8px",
});

export const WrappingGeneralDiv = styled("div", {
  display: "flex",
  flexDirection: "row",
});

export const WrappingAddButton = styled(Button, {
  width: "50%",
});

export const CategoryNameInput = styled(Input, {
  marginBottom: "16px",
});

export const WrappingTxt = styled(Label, {
  marginRight: "8px",
  width: "auto",
});
