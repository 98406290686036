import { styled } from "@vippsno/ds-core";

export const StyledDatePicker = styled("span", {
  "& .react-datepicker": {
    display: "inline-block",
    position: "relative",
    margin: "6px 0",
    backgroundColor: "$datepicker-main-color-background-default",
    border: "2px solid $datepicker-main-color-border-default",
    borderRadius: "6px",
    fontSize: "16px",
    color: "$datepicker-main-color-text-default",

    "&-popper": {
      zIndex: 1,
      width: "max-content",
    },

    "&__triangle": {
      display: "none",
    },

    "&__header": {
      position: "relative",
      margin: "16px 0 6px 16px",
    },

    "&__navigation": {
      zIndex: 1,
      position: "absolute",
      top: "16px",
      width: "32px",
      height: "32px",
      border: "none",
      borderRadius: "50%",
      background: "none",
      backgroundColor: "$datepicker-navigation-color-background-default",
      lineHeight: 0,
      color: "$datepicker-navigation-color-text-default",
      textAlign: "center",
      cursor: "pointer",

      "&--previous": {
        right: "56px",
      },

      "&--next": {
        right: "16px",
      },

      "&--previous--disabled, &--next--disabled": {
        backgroundColor: "$datepicker-navigation-color-background-disabled",
        color: "$datepicker-navigation-color-text-disabled",
        cursor: "not-allowed",
      },
    },

    "&__month": {
      margin: "0 16px 16px",
      textAlign: "center",

      "&-text": {
        display: "inline-block",
        width: "64px",
        margin: "2px",
      },
    },

    "&__current-month": {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "32px",
    },

    "&__day-names": {
      marginTop: "16px",
      fontSize: "14px",
      fontWeight: "$medium",
      color: "$datepicker-day-names-color-text-default",
    },

    "&__day-name": {
      display: "inline-block",
      textAlign: "center",
      width: "42px",
    },

    "&__day": {
      display: "inline-block",
      width: "32px",
      margin: "5px",
      borderRadius: "50%",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "32px",
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "$datepicker-day-color-background-hover",
      },

      "&--outside-month": {
        color: "$datepicker-outside-month-color-text-default",
      },

      "&--today": {
        backgroundColor: "$datepicker-today-color-background-default",
      },

      "&--selected": {
        backgroundColor: "$datepicker-selected-color-background-default",
        fontWeight: "bold",
        color: "$datepicker-selected-color-text-default",

        "&:hover": {
          backgroundColor: "$datepicker-selected-color-background-hover",
        },
      },

      "&--disabled": {
        pointerEvents: "none",
        backgroundColor: "transparent",
        color: "transparent",
        cursor: "default",
      },
    },
    "&__aria-live": {
      position: "absolute",
      overflow: "hidden",
      width: 0,
    },
  },
});
