/* tslint:disable */
/* eslint-disable */
/**
 * Vipps MobilePay Gift Wrappings Admin API
 * API with endpoints for admin users
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface GiftAssetEffect
 */
export interface GiftAssetEffect {
    /**
     * 
     * @type {string}
     * @memberof GiftAssetEffect
     */
    type: GiftAssetEffectTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GiftAssetEffect
     */
    emojis?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum GiftAssetEffectTypeEnum {
    BasicConfetti = 'basic_confetti',
    EmojiConfetti = 'emoji_confetti'
}

