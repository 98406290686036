import { GiftAssetVariantTypeEnum } from "../generated/models";

const SCREEN_WIDTH = 800;

export const MAX_NUMBER_OF_EMOJIS = 3;
export const BACKSPACE_KEY = "Backspace";
export const DELETE_KEY = "Delete";

export const accessibilityDescriptionPlaceholder =
  "Red confetti, gifts, and balloons spread like confetti accross the screen";

export const emojiRegex =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;

export const popularColors: string[] = [
  "#000000",
  "#FFFFFF",
  "#FF0000",
  "#00FF00",
  "#0000FF",
  "#FFFF00",
  "#00FFFF",
  "#FF00FF",
  "#C0C0C0",
  "#808080",
  "#800000",
  "#808000",
  "#008000",
  "#800080",
  "#008080",
  "#000080",
  "#FFA500",
  "#FFC0CB",
  "#800080",
  "#FFD700",
];

export function generateShortName(name: string | undefined): string {
  if (!name) {
    return "";
  }

  const words = name.split(" ");
  const camelCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  const shortName = camelCaseWords.join("_");

  return shortName;
}

export function formatDateRange(
  activeFromTs: string | undefined,
  activeToTs: string | undefined,
): string {
  if (!activeFromTs && !activeToTs) {
    return "No";
  }
  let dateString = "";
  if (activeFromTs) {
    dateString = dateString.concat(new Date(activeFromTs).toLocaleDateString());
  }
  if (activeToTs) {
    dateString = dateString
      .concat(" - ")
      .concat(new Date(activeToTs).toLocaleDateString());
  }
  return dateString;
}

export function handleResizeAndOrientationChange(
  setIsSmallScreen: (value: React.SetStateAction<boolean>) => void,
  window: Window,
) {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= SCREEN_WIDTH);
  };

  window.addEventListener("resize", handleResize);
  window.addEventListener("orientationchange", handleResize);

  // Call handleResize once to set the initial value
  handleResize();

  // Remove the event listener when the component unmounts
  return () => {
    window.removeEventListener("resize", handleResize);
    window.removeEventListener("orientationchange", handleResize);
  };
}

export function getGiftAssetVariantType(
  typeString: string,
): GiftAssetVariantTypeEnum {
  if (typeString === "light") {
    return GiftAssetVariantTypeEnum.Light;
  } else if (typeString === "dark") {
    return GiftAssetVariantTypeEnum.Dark;
  }
  return GiftAssetVariantTypeEnum.Light;
}
