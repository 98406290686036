import React from "react";
import { Sidesheet } from "@vippsno/ds-modal";
import { Button } from "@vippsno/ds-button";
import { GiftCategory } from "../../../generated/models";
import { CategoryNameInput, GeneralForm, WrappingGeneralDiv } from "../styles";
import { InputError } from "@vippsno/ds-form";

interface CategoryEditorProps {
  category: GiftCategory;
  heading: string;
  isOpen: boolean;
  isLoading: boolean;
  inputValidationFailure: string;
  onClose: () => void;
  onSubmit: (giftCategory: GiftCategory) => Promise<void>;
}

function CategoryEditor(props: CategoryEditorProps) {
  const {
    category,
    heading,
    isOpen,
    isLoading,
    inputValidationFailure,
    onClose,
    onSubmit,
  } = props;

  const [giftCategory, setGiftCategory] = React.useState<GiftCategory>({
    ...category,
  });

  const handleSubmit = async (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    await onSubmit(giftCategory);
  };

  return (
    <Sidesheet
      open={isOpen}
      onClose={onClose}
      onCancel={onClose}
      header={heading}
      closeLabel="Close"
      actions={{
        primary: (
          <Button
            key="save"
            variant="primary"
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
            loading-label={"Loading"}
          >
            Save changes
          </Button>
        ),
        secondary: (
          <Button
            key="cancel"
            variant="secondary"
            onClick={onClose}
            loading-label={"Loading"}
          >
            Cancel
          </Button>
        ),
      }}
    >
      <GeneralForm>
        <CategoryNameInput
          name="categoryName"
          label="Category name"
          value={giftCategory.title}
          onChange={(e) => {
            setGiftCategory({ ...giftCategory, title: e.target.value });
          }}
        />
      </GeneralForm>
      {inputValidationFailure && (
        <WrappingGeneralDiv>
          <InputError>{inputValidationFailure}</InputError>
        </WrappingGeneralDiv>
      )}
    </Sidesheet>
  );
}

export default CategoryEditor;
