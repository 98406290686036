import { styled } from "@vippsno/ds-core";
import { Grid, GridBlock } from "@vippsno/ds-grid";

export const StyledGrid = styled(Grid, {
  height: "100vh",
});

export const StyledGridBlock = styled(GridBlock, {
  backgroundColor: "$gray20",
});
