import React from "react";
import MoneyGiftsAdminApi from "./money-gifts-api";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  CategoryWithAssets,
  GiftAsset,
  GiftAssetRead,
  GiftCategory,
  GiftCategoryRead,
} from "../generated";

export const MoneyGiftsAdminApiContext =
  React.createContext<MoneyGiftsAdminApi>(null);

export const useMoneyGiftsAdminApi = () =>
  React.useContext(MoneyGiftsAdminApiContext);

const categoryAssetKeys = {
  all: ["categories"] as const,
  lists: () => [...categoryAssetKeys.all, "list"] as const,
  list: () => [...categoryAssetKeys.lists()] as const,
  details: () => [...categoryAssetKeys.all, "detail"] as const,
  detail: (id: number) => [...categoryAssetKeys.details(), id] as const,
};

export const useCategories = () => {
  const api = useMoneyGiftsAdminApi();

  return useQuery({
    queryKey: categoryAssetKeys.lists(),
    queryFn: () => api.getAllCategories(),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    staleTime: 1000 * 60 * 3, // 3 minutes,
    cacheTime: 1000 * 60 * 15, // 15 minutes
  });
};

const updateCategoryInCache = (
  queryClient: QueryClient,
  category: GiftCategoryRead,
) => {
  if (!category) {
    return;
  }
  queryClient
    .getQueryCache()
    .findAll(categoryAssetKeys.lists())
    .forEach((query) => {
      queryClient.setQueryData(query.queryKey, categoryUpdater(category));
    });
};

const updateAssetInCache = (queryClient: QueryClient, asset: GiftAssetRead) => {
  if (!asset) {
    return;
  }
  queryClient
    .getQueryCache()
    .findAll(categoryAssetKeys.lists())
    .forEach((query) => {
      queryClient.setQueryData(query.queryKey, assetUpdater(asset));
    });
};

const categoryUpdater = (category: GiftCategoryRead) => {
  return (oldData: Array<CategoryWithAssets>) => {
    if (!oldData) {
      return oldData as unknown as Array<CategoryWithAssets>;
    }

    const itemExist = oldData.some(
      (catWithAssets) => catWithAssets.category.id === category.id,
    );
    if (!itemExist) {
      // new category, just adding to query cache
      return [
        ...oldData,
        {
          category: category,
          assets: [],
        },
      ];
    } else {
      // replace category in query cache
      const newData = [...oldData];
      const itemIndex = newData.findIndex(
        (catWithAssets) => catWithAssets.category.id === category.id,
      );
      if (itemIndex > -1) {
        const assets = [...newData[itemIndex].assets];
        newData[itemIndex] = {
          category: category,
          assets: assets,
        };
      }
      return newData;
    }
  };
};

const assetUpdater = (asset: GiftAssetRead) => {
  return (oldData: Array<CategoryWithAssets>) => {
    if (!oldData) {
      return oldData as unknown as Array<CategoryWithAssets>;
    }

    const itemExist = oldData.some((catWithAssets) =>
      catWithAssets.assets.some((as) => as.id === asset.id),
    );

    if (!itemExist) {
      // new asset, adding to category
      const newData = [...oldData];
      const itemIndex = newData.findIndex(
        (catWithAssets) => catWithAssets.category.id === asset.categoryId,
      );
      if (itemIndex > -1) {
        const category = { ...newData[itemIndex].category };
        const assets = [...newData[itemIndex].assets, asset];
        newData[itemIndex] = {
          category: category,
          assets: assets,
        };
      }
      return newData;
    } else {
      // update existing asset on category
      const newData = [...oldData];
      const itemIndex = newData.findIndex(
        (catWithAssets) => catWithAssets.category.id === asset.categoryId,
      );
      if (itemIndex > -1) {
        const assetItemIndex = newData[itemIndex].assets.findIndex(
          (oldAsset) => oldAsset.id === asset.id,
        );
        if (assetItemIndex > -1) {
          const category = { ...newData[itemIndex].category };
          const newAssets = [...newData[itemIndex].assets];
          newAssets[assetItemIndex] = {
            id: asset.id,
            categoryId: asset.categoryId,
            active: asset.active,
            activeFromTs: asset.activeFromTs,
            activeToTs: asset.activeToTs,
            sortIndex: asset.sortIndex,
            fee: asset.fee,
            accessibilityText: asset.accessibilityText,
            name: asset.name,
            variants: [...asset.variants],
          };
          newData[itemIndex] = {
            category: category,
            assets: newAssets,
          };
        }
      }
      return newData;
    }
  };
};

export const useCreateCategory = (
  onSuccess?: () => void,
  onError?: (error: Error) => void,
) => {
  const api = useMoneyGiftsAdminApi();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { category: GiftCategory }) => api.createCategory(params.category),
    {
      onSuccess: (giftCat: GiftCategoryRead) => {
        updateCategoryInCache(queryClient, giftCat);
        onSuccess && onSuccess();
      },
      onError: (error: Error) => {
        onError(error);
      },
    },
  );
};

export const useEditCategory = (
  onSuccess?: () => void,
  onError?: (error: Error) => void,
) => {
  const api = useMoneyGiftsAdminApi();
  const queryClient = useQueryClient();

  return useMutation(
    (params: { category: GiftCategory; categoryId: number }) =>
      api.editCategory(params.category, params.categoryId),
    {
      onSuccess: (giftCat: GiftCategoryRead) => {
        updateCategoryInCache(queryClient, giftCat);
        onSuccess && onSuccess();
      },
      onError: (error: Error) => {
        onError(error);
      },
    },
  );
};

export const useUpdateAsset = (
  onSuccess?: () => void,
  onError?: (error: Error) => void,
) => {
  const api = useMoneyGiftsAdminApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { asset: GiftAsset; assetId: number }) =>
      api.updateAsset(params.asset, params.assetId),
    onSuccess: (giftAsset: GiftAssetRead) => {
      updateAssetInCache(queryClient, giftAsset);
      onSuccess && onSuccess();
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useCreateAsset = (
  onSuccess?: () => void,
  onError?: (error: Error) => void,
) => {
  const api = useMoneyGiftsAdminApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { asset: GiftAsset }) => api.createAsset(params.asset),
    onSuccess: (giftAsset: GiftAssetRead) => {
      updateAssetInCache(queryClient, giftAsset);
      onSuccess && onSuccess();
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};

export const useUploadImage = (
  onSuccess?: (url: string) => void,
  onError?: (error: Error) => void,
) => {
  const api = useMoneyGiftsAdminApi();

  return useMutation({
    mutationFn: (params: { file: Blob }) => api.uploadImage(params.file),
    onSuccess: (url: string) => {
      onSuccess && onSuccess(url);
    },
    onError: (error: Error) => {
      onError(error);
    },
  });
};
