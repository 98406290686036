import { Configuration } from "../generated/configuration";
import { AxiosRequestConfig } from "axios";
import { protectedResources } from "../auth/auth";

export const MoneyGiftsAdminConfig = new Configuration({
  basePath: protectedResources.moneyGiftsApi.base,
});

export const MoneyGiftsRequestConfig: AxiosRequestConfig = {
  withCredentials: true,
};
