import React from "react";
import { Banner } from "@vippsno/ds-message";
import { WrappingGeneralDiv } from "./styles";

interface BannerOverlayProps {
  isOpen: boolean;
  bannerType: string;
  bannerTxt: string;
  onBannerClose: () => void;
}
function BannerOverlay(props: BannerOverlayProps) {
  const { isOpen, bannerType, bannerTxt, onBannerClose } = props;
  type Variant = "default" | "success" | "warning" | "error";

  return (
    <WrappingGeneralDiv>
      {isOpen && (
        <Banner
          variant={bannerType as Variant}
          dismissable={true}
          onClose={onBannerClose}
          compact={false}
          css={{
            left: "10px",
            right: "10px",
            height: "50px",
            position: "absolute",
            "z-index": "10",
            "box-shadow": "0 5px 20px 0 rgba(0, 0, 0, 0.04)",
          }}
        >
          {bannerTxt}
        </Banner>
      )}
    </WrappingGeneralDiv>
  );
}

export default BannerOverlay;
