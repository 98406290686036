import React from "react";
import { GiftAsset } from "../../generated/models";
import { WrappingGeneralDiv } from "./styles";
import { useCategories, useCreateAsset } from "../../api/money-gifts-api-hooks";
import WrappingEditor from "./common/WrappingEditor";

interface CreateWrappingProps {
  isOpen: boolean;
  onClose: () => void;
  onBannerOpen: (type: string, txt: string) => void;
}

function CreateWrapping(props: CreateWrappingProps) {
  const { onClose, isOpen, onBannerOpen } = props;

  const categories = useCategories();

  const initialCategoryId =
    categories.data &&
    Array.isArray(categories.data) &&
    categories.data[0] &&
    categories.data[0].category.id
      ? categories.data[0].category.id
      : 0;

  const initial: GiftAsset = {
    categoryId: initialCategoryId,
    active: false,
    sortIndex: 0,
    fee: 0,
    accessibilityText: "",
    name: "",
    variants: [],
  };

  const onError = (error: Error) => {
    const errorMsg = error instanceof Error ? error.message : "Unknown error";
    onBannerOpen("error", `Failed to create wrapping paper: ${errorMsg}`);
    onClose();
  };

  const onSuccess = () => {
    onBannerOpen("success", "Wrapping paper was successfully created");
    onClose();
  };

  const createAsset = useCreateAsset(onSuccess, onError);

  const handleSubmit = async (giftWrappingAsset: GiftAsset) => {
    const newAsset: GiftAsset = { ...giftWrappingAsset };

    createAsset.mutate({ asset: newAsset });
  };

  return (
    <WrappingGeneralDiv>
      <WrappingEditor
        giftAsset={initial}
        giftAssetId={-1}
        heading={"New wrapping paper"}
        confirmTxt={"Do you want to create this wrapping paper?"}
        confirmButtonTxt={"Yes, create it"}
        isNewAsset={true}
        isOpen={isOpen}
        isLoading={createAsset.isLoading}
        onSubmit={handleSubmit}
        onClose={onClose}
      />
    </WrappingGeneralDiv>
  );
}

export default CreateWrapping;
