import React from "react";
import { Route, Routes } from "react-router-dom";
import Wrappings from "./wrappings/Wrappings";

function Content() {
  return (
    <Routes>
      <Route path="/" element={<Wrappings />} />
    </Routes>
  );
}

export default Content;
