import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Heading3 } from "@vippsno/ds-typography";
import { LinkIcon } from "@vippsno/ds-icon";
import { Separator } from "@vippsno/ds-separator";
import { VippsMobilePay } from "../../assets";
import { SignInButton, SignOutButton } from "../content/account";
import {
  SidebarDiv,
  SidebarLink,
  SidebarLogo,
  SidebarLogoDiv,
  StyledButton,
  StyledSidebar,
} from "./styles";
import EnvBadge from "./EnvBadge";

function Sidebar() {
  return (
    <StyledSidebar>
      <SidebarDiv>
        <SidebarLogoDiv>
          <SidebarLogo src={VippsMobilePay} alt="Vipps Mobile Pay logo" />
        </SidebarLogoDiv>
        <Heading3 style={{ margin: "0" }}>Wrapping paper admin</Heading3>
        <EnvBadge />
      </SidebarDiv>
      <AuthenticatedTemplate>
        <StyledButton variant="primary">Wrapping papers</StyledButton>
      </AuthenticatedTemplate>
      <StyledButton variant="secondary" icon={<LinkIcon />}>
        <SidebarLink href="#">User behaviour data</SidebarLink>
      </StyledButton>
      <StyledButton variant="secondary" icon={<LinkIcon />}>
        <SidebarLink href="https://app.lokalise.com/project/31830180649be59f0586f8.42102499/?view=multi">
          Language
        </SidebarLink>
      </StyledButton>
      <Separator padded />
      <SignOutButton />
      <SignInButton />
    </StyledSidebar>
  );
}

export default Sidebar;
