import {
  CategoryWithAssets,
  GiftAsset,
  GiftAssetVariantTypeEnum,
} from "../../generated";

export const isScheduledAsset = (giftAsset: GiftAsset) => {
  return (
    giftAsset.activeFromTs != undefined || giftAsset.activeToTs != undefined
  );
};

export const validatFieldsOnCreate = (giftAsset: GiftAsset): string[] => {
  const newFailures = validateFields(giftAsset);
  if (!giftAsset.name) {
    newFailures.push("Name is required");
  }
  return newFailures;
};

export const validateFields = (giftAsset: GiftAsset): string[] => {
  const newFailures: string[] = [];
  if (!giftAsset.categoryId) {
    newFailures.push("Category is required");
  }
  if (!giftAsset.accessibilityText) {
    newFailures.push("Accessibility text is required");
  }
  if (isScheduledAsset(giftAsset)) {
    if (!giftAsset.activeFromTs) {
      newFailures.push("Start date for scheduling is missing");
    } else {
      const selectedStartDate = new Date(giftAsset.activeFromTs);
      const selectedEndDate = giftAsset.activeToTs
        ? new Date(giftAsset.activeToTs)
        : undefined;
      if (selectedEndDate && selectedEndDate < selectedStartDate) {
        newFailures.push("End date for scheduling must be after start date");
      } else if (
        selectedEndDate &&
        selectedEndDate.getTime() == selectedStartDate.getTime()
      ) {
        newFailures.push("Start and end date for scheduling can't be the same");
      }
    }
  }

  const containsLight = giftAsset.variants.find((variant) => {
    return variant.type == GiftAssetVariantTypeEnum.Light;
  });
  const containsDark = giftAsset.variants.find((variant) => {
    return variant.type == GiftAssetVariantTypeEnum.Dark;
  });
  if (giftAsset.variants.length != 2) {
    newFailures.push("Light and dark variant are required, max one of each");
  } else {
    if (!containsLight || !containsDark) {
      newFailures.push(
        "Both light and dark variant is required for a wrapping paper",
      );
    }
    giftAsset.variants.map((variant) => {
      if (!variant.type) {
        newFailures.push("Type of variant is required");
      }
      if (!variant.baseColor) {
        newFailures.push(
          "Base color for variant " + variant.type + " is required",
        );
      }
      if (!variant.tintColor) {
        newFailures.push(
          "Tint color for variant " + variant.type + " is required",
        );
      }
      if (
        !variant.effect ||
        !variant.effect.type ||
        variant.effect.emojis.length === 0
      ) {
        newFailures.push("Emojis for variant " + variant.type + " is required");
      }
      if (!variant.assetURL) {
        newFailures.push(
          "Image file for variant " + variant.type + " is required",
        );
      }
    });
  }
  return newFailures;
};

export const diffFields = (
  newGiftAsset: GiftAsset,
  oldGiftAsset: GiftAsset,
  categories: CategoryWithAssets[],
): Map<string, string> => {
  const diffs: Map<string, string> = new Map<string, string>();
  if (newGiftAsset.active != oldGiftAsset.active) {
    diffs.set("Active: ", oldGiftAsset.active + " => " + newGiftAsset.active);
  }
  if (newGiftAsset.accessibilityText != oldGiftAsset.accessibilityText) {
    diffs.set(
      "Accessibility text: ",
      oldGiftAsset.accessibilityText + " => " + newGiftAsset.accessibilityText,
    );
  }

  if (newGiftAsset.categoryId != oldGiftAsset.categoryId) {
    if (categories) {
      const initialCatName =
        categories.find(
          (catWithAssets) =>
            catWithAssets.category.id === oldGiftAsset.categoryId,
        )?.category?.title || "(id:" + oldGiftAsset.categoryId + ")";
      const newCatName =
        categories.find(
          (catWithAssets) =>
            catWithAssets.category.id === newGiftAsset.categoryId,
        )?.category?.title || "(id:" + newGiftAsset.categoryId + ")";
      diffs.set("Category: ", initialCatName + " => " + newCatName);
    } else {
      diffs.set(
        "Category: ",
        oldGiftAsset.categoryId + " => " + newGiftAsset.categoryId,
      );
    }
  }

  const initialActiveFromDate = oldGiftAsset.activeFromTs
    ? new Date(oldGiftAsset.activeFromTs)
    : undefined;
  const activeFromDate = newGiftAsset.activeFromTs
    ? new Date(newGiftAsset.activeFromTs)
    : undefined;
  if (activeFromDate?.getTime() != initialActiveFromDate?.getTime()) {
    const originalDate = initialActiveFromDate ? initialActiveFromDate : '""';
    const newDate = activeFromDate ? activeFromDate : '""';
    diffs.set("Scheduled start: ", originalDate + " => " + newDate);
  }
  const initialActiveToDate = oldGiftAsset.activeToTs
    ? new Date(oldGiftAsset.activeToTs)
    : undefined;
  const activeToDate = newGiftAsset.activeToTs
    ? new Date(newGiftAsset.activeToTs)
    : undefined;
  if (activeToDate?.getTime() != initialActiveToDate?.getTime()) {
    const originalDate = initialActiveToDate ? initialActiveToDate : '""';
    const newDate = activeToDate ? activeToDate : '""';
    diffs.set("Scheduled stop: ", originalDate + " => " + newDate);
  }

  // made based on current functionality, variants can't be added or removed, and must have light and dark
  oldGiftAsset.variants.map((variant) => {
    newGiftAsset.variants.map((changedVariant) => {
      if (variant.type === changedVariant.type) {
        if (variant.baseColor != changedVariant.baseColor) {
          diffs.set(
            "Variant " + variant.type + "-baseColor" + " : ",
            variant.baseColor + " => " + changedVariant.baseColor,
          );
        }
        if (variant.tintColor != changedVariant.tintColor) {
          diffs.set(
            "Variant " + variant.type + "-tintColor" + " : ",
            variant.tintColor + " => " + changedVariant.tintColor,
          );
        }
        if (variant.assetURL != changedVariant.assetURL) {
          diffs.set(
            "Variant " + variant.type + "-assetURL" + " : ",
            variant.assetURL + " => " + changedVariant.assetURL,
          );
        }
        if (variant.effect.emojis != changedVariant.effect.emojis) {
          diffs.set(
            "Variant " + variant.type + "-emojis" + " : ",
            variant.effect.emojis + " => " + changedVariant.effect.emojis,
          );
        }
      }
    });
  });
  return diffs;
};
