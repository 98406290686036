import React from "react";
import { Heading3 } from "@vippsno/ds-typography";
import { Button, ButtonGroup } from "@vippsno/ds-button";
import { AddIcon } from "@vippsno/ds-icon";
import { StyledWrappingsHeading } from "./styles";
import { WrappingsHeadingProps } from "./WrappingsHeading";

function SmallScreenWrappingsHeading(props: WrappingsHeadingProps) {
  const { onCreateCategoryOpen, onCreateWrappingOpen } = props;

  return (
    <StyledWrappingsHeading>
      <ButtonGroup stretched>
        <Heading3>Wrapping papers</Heading3>
        {/*<Button variant="secondary">Preview production</Button>*/}
        <Button variant="primary" onClick={onCreateWrappingOpen}>
          <AddIcon />
          Add new wrapping paper
        </Button>
        <Button variant="secondary" onClick={onCreateCategoryOpen}>
          <AddIcon />
          Add new category
        </Button>
      </ButtonGroup>
    </StyledWrappingsHeading>
  );
}

export default SmallScreenWrappingsHeading;
