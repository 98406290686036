import React from "react";
import WrappingsHeading from "./WrappingsHeading";
import Categories from "../categories/Categories";
import {
  CreateCategory,
  CreateWrapping,
  EditCategory,
  EditWrapping,
} from "../../modals";
import { GiftAssetRead, GiftCategoryRead } from "../../../generated/models";
import { StyledContent } from "./styles";
import BannerOverlay from "../../modals/BannerOverlay";

function Wrappings() {
  const [isEditWrappingOpen, setIsEditWrappingOpen] = React.useState(false);
  const [isCreateWrappingOpen, setIsCreateWrappingOpen] = React.useState(false);
  const [isCreateCategoryOpen, setIsCreateCategoryOpen] = React.useState(false);
  const [isEditCategoryOpen, setIsEditCategoryOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] =
    React.useState<GiftCategoryRead | null>(null);
  const [selectedGiftAsset, setSelectedGiftAsset] =
    React.useState<GiftAssetRead | null>(null);
  const [showBanner, setShowBanner] = React.useState<boolean>(false);
  const [bannerVariant, setBannerVariant] = React.useState<string>("");
  const [bannerTxt, setBannerTxt] = React.useState<string>("");

  const handleEditWrappingOpen = (giftAsset: GiftAssetRead) => {
    setSelectedGiftAsset(giftAsset);
    setIsEditWrappingOpen(true);
  };

  const handleEditWrappingClose = () => {
    setIsEditWrappingOpen(false);
  };

  const handleCreateWrappingOpen = () => {
    setIsCreateWrappingOpen(true);
  };

  const handleCreateWrappingClose = () => {
    setIsCreateWrappingOpen(false);
  };

  const handleEditCategoryOpen = (category: GiftCategoryRead) => {
    setSelectedCategory(category);
    setIsEditCategoryOpen(true);
  };

  const handleEditCategoryClose = () => {
    setIsEditCategoryOpen(false);
  };

  const handleCreateCategoryOpen = () => {
    setIsCreateCategoryOpen(true);
  };

  const handleCreateCategoryClose = () => {
    setIsCreateCategoryOpen(false);
  };

  const handleBannerOpen = (type: string, txt: string) => {
    setBannerVariant(type);
    setBannerTxt(txt);
    setShowBanner(true);
  };

  const handleBannerClose = () => {
    setShowBanner(false);
  };

  return (
    <StyledContent>
      <BannerOverlay
        isOpen={showBanner}
        bannerType={bannerVariant}
        bannerTxt={bannerTxt}
        onBannerClose={handleBannerClose}
      ></BannerOverlay>
      <WrappingsHeading
        onCreateWrappingOpen={handleCreateWrappingOpen}
        onCreateCategoryOpen={handleCreateCategoryOpen}
      />
      <Categories
        onEditWrappingOpen={handleEditWrappingOpen}
        onEditCategoryOpen={handleEditCategoryOpen}
      />
      {isEditWrappingOpen && (
        <EditWrapping
          isOpen={isEditWrappingOpen}
          onClose={handleEditWrappingClose}
          giftAsset={selectedGiftAsset}
          onBannerOpen={handleBannerOpen}
        />
      )}
      {isCreateWrappingOpen && (
        <CreateWrapping
          isOpen={isCreateWrappingOpen}
          onClose={handleCreateWrappingClose}
          onBannerOpen={handleBannerOpen}
        />
      )}
      {isCreateCategoryOpen && (
        <CreateCategory
          isOpen={isCreateCategoryOpen}
          onClose={handleCreateCategoryClose}
          onBannerOpen={handleBannerOpen}
        />
      )}
      {isEditCategoryOpen && (
        <EditCategory
          isOpen={isEditCategoryOpen}
          onClose={handleEditCategoryClose}
          onBannerOpen={handleBannerOpen}
          category={selectedCategory}
        />
      )}
    </StyledContent>
  );
}

export default Wrappings;
